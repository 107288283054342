<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" height="48px">
        <v-toolbar-title >Gestion des evènements / tâches</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn color="success" depressed class="white--text" to="/events/0">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Créer</span>
        </v-btn> -->
    </v-toolbar>
    <v-text-field
        append-icon="mdi-magnify" label="Recherche"
        solo clearable single-line hide-details v-model="search" @blur="saveSearchToCache()" class="ma-4 pa-0">
    </v-text-field>
    <v-card class="ma-4">
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:[`item.eve_table_id`]="{ item }">
            <v-chip small outlined label>{{ tableType[item.eve_table_id] }}</v-chip>
        </template>
        <template v-slot:[`item.eve_title`]="{ item }">
            <v-chip small outlined label class="text-truncate">{{ item.eve_title }}</v-chip>
        </template>
        <template v-slot:[`item.eve_sta_id`]="{ item }">
            <v-chip small outlined label>{{ getCacheState('GEN', item.eve_sta_id).sta_label }}</v-chip>
        </template>
        <template v-slot:[`item.eve_typ_id`]="{ item }">
            <v-chip small outlined label>{{ getCacheType('EVE', item.eve_typ_id).typ_name }}</v-chip>
        </template>
        <template v-slot:[`item.eve_date_start`]="{ item }">
            {{ locDateFormat.formatLocale(item.eve_date_start, 'fr') }}
        </template>
        <template v-slot:[`item.eve_resolve_percent`]="{ item }">
            <v-rating :value="(item.eve_resolve_percent) / 20" readonly background-color="primary" color="yellow accent-4" dense size="18" half-increments ></v-rating>
        </template>
        <template v-slot:[`item.eve_created_at`]="{ item }">
            <v-btn outlined small color="grey" class="ml-2 elevation-1" @click="openEvent(item.eve_id, item.eve_table_id, item.eve_xxx_id)">
                <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>

    <v-dialog v-model="modalEvent" max-width="900" style="z-index:1056;">
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Evenement</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalEvent', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
            <EventForm class="elevation-2" 
                v-if="modalEvent" :toast="toast" :switchModal="switchModal"
                :xxxId="eveInfo.eve_xxx_id" :eveId="eveInfo.eve_id" :eveTable="eveInfo.eve_table_id" :eveType="'ACT'" :title="'Action / evenement'">
            </EventForm>
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheMutations } from "../store/cache.module"
import EventForm        from '@/components/event/EventForm.vue'

export default {
    name: 'events',
    props: ['toast'],
    components: { EventForm },
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Object',          align: 'left', value: 'eve_table_id'},
                { text: 'Description',     align: 'left', value: 'eve_title'},
                { text: 'Statut',          align: 'left', value: 'eve_sta_id'},
                { text: 'Date',            align: 'left', value: 'eve_date_start'},
                { text: 'Résolue',         align: 'left', value: 'eve_resolve_percent'},
                { text: 'Modification',    align: 'right',value: 'eve_created_at' }
            ],
            tableType: {
                cad: 'Objet carte', pro: 'Projet'
            },
            items: [],
            eveInfo: { eve_id: 0, eve_sta_id : 'EVE01', eve_table_id: '', eve_xxx_id  : '' },
            locDateFormat: dateLocale,
            enableAdd: true,
            modalEvent : false,
            cacheTab: this.$storage.localStorage.get( this.$APP_COD + "_cache" ),
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        }
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('equipments') || ''
        this.getItems()
    },
    methods: {
        getItems(){
            this.$http.get( '/events?per_page=false' ).then( (response) => {
                this.items = response.data.data
            })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('events', this.search)
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalEvent'
            mode    = mode || 0
            refresh = refresh || false
            this[name] = mode
            if( mode == 0 ){
                this.ctp_id = 0
            }
            if( refresh ){ 
                this.getItems()
            }
        },
        openEvent(id, xxx_id, table_id){
            this.eveInfo.eve_id   = id
            this.eveInfo.xxx_id   = xxx_id
            this.eveInfo.table_id = table_id
            this.switchModal('modalEvent', 1)
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    }
}
</script>